import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  IoMdTrash,
  IoMdCreate,
  IoMdSearch,
  IoMdClose,
  IoMdEye,
} from "react-icons/io";

const Dropper = ({ title, open = false }) => {
  const [modal, setModal] = useState(open);
  const [query, setQuery] = useState("");

  const image =
    "https://alphacdn.qayadat.org/assets/resize.php?url=https://play.qayadat.org/uploads/series/1705335017_a85c090c7cdbd9cf2f0c.jpg&width=1920&height=auto&quality=50";

  const iconStyle = {
    size: 22,
  };

  return (
    <div className="w-full bg-black rounded-lg mb-4 px-4">
      <div
        onClick={() => {
          setModal(!modal);
        }}
        className={`flex items-center justify-between py-[12px] ${
          modal ? " border-b border-gray-500" : ""
        } cursor-pointer`}
      >
        <h2 className="text-[16px] font-[500] tracking-wide ">{title}</h2>
        {modal ? (
          <FaChevronUp size={20} style={{ fill: "gray" }} />
        ) : (
          <FaChevronDown style={{ fill: "gray" }} size={20} />
        )}
      </div>

      {modal && (
        <div className="w-full h-10 rounded-lg mt-2 border border-gray-500 flex items-center justify-center">
          <IoMdSearch {...iconStyle} className="mx-2" />
          <input
            type="text"
            value={query}
            className="bg-transparent h-full w-full"
            placeholder="Search..."
            onChange={(e) => setQuery(e.target.value)}
          />
          {query && (
            <IoMdClose
              {...iconStyle}
              onClick={() => {
                setQuery("");
              }}
              className="cursor-pointer"
            />
          )}
        </div>
      )}

      {modal && (
        <div className="py-2">
          {[1, 2, 3, 4, 5].map((item, index) => {
            return (
              <div
                key={index}
                className="flex w-full h-20 mb-1 items-center justify-between"
              >
                <img
                  src={image}
                  alt=""
                  className="h-full w-32 object-cover rounded"
                />

                <div className="flex flex-col w-[40%] mx-2">
                  <h3 className="line-clamp-1 font-[500] text-[16px] capitalize tracking-wide mb-[2px]">
                    Kurulus Osman
                  </h3>

                  <p className="text-neutral-500 font-[500] line-clamp-2 text-[12px] tracking-wide leading-[15px]">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Exercitationem odio ullam doloremque quia neque alias
                    quaesdfs delectus obcaecati iure ab? Error nisi tempora
                    eligendi optio obcaecati eaque voluptatum corporis. Facere.
                  </p>
                </div>

                <div className="flex gap-2 mx-2 text-[13px] font-bold">
                  <span className="font-[400]">Action</span>
                  <span className="font-[400]">Drama</span>
                  <span className="font-[400]">Thriller</span>
                </div>

                <div className="flex items-center justify-between">
                  <IoMdEye
                    {...iconStyle}
                    style={{ fill: "gray" }}
                    className="mx-2"
                  />
                  <span className="text-gray-200 text-[13px]">102 Views</span>
                </div>

                <div className="flex gap-0 mx-2 flex-col text-[14px] font-[400] text-gray-300">
                  <span>Urdu</span>
                  <span>2 days Ago</span>
                </div>

                <div className="flex gap-4 mx-2">
                  <IoMdCreate
                    {...iconStyle}
                    style={{ fill: "#0cc7de" }}
                    className="edit cursor-pointer"
                  />
                  <IoMdTrash
                    {...iconStyle}
                    style={{ fill: "crimson" }}
                    className="trash cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropper;
