import axios from "axios";

const api_url = "https://backend.islamicmediaofficial.com/api/v1";

const searchUrl = (query) => `${api_url}/search?query=${query}`;

//endpoint urls
const movie_url = `${api_url}/movie`;
const series_url = `${api_url}/series`;
// const episode_url = `${api_url}/video`;

const trendingUrl = `${api_url}/trending`;
//movies
const trendingMoviesUrl = `${movie_url}/trending`;
const popularMoviesUrl = `${movie_url}/popular`;
const recentMoviesUrl = `${movie_url}/recent`;
const getMovieUrl = (id) => `${movie_url}/${id}`;
const similarMoviesUrl = (id, genre) =>
  `${movie_url}/similar?id=${id}&genre=${genre}`;

//series
const trendingSeriesUrl = `${series_url}/trending`;
const popularSeriesUrl = `${series_url}/popular`;
const recentSeriesUrl = `${series_url}/recent`;
const getSeriesUrl = (id) => `${series_url}/${id}`;

//series
// const trendingEpisodeUrl = `${episode_url}/trending`;
// const popularEpisodeUrl = `${episode_url}/popular`;
// const recentEpisodeUrl = `${episode_url}/recent`;

const apiGetCall = async (
  endpoint,
  headers = {},
  method = "GET",
  params = {}
) => {
  try {
    const response = await axios.request({
      method,
      url: endpoint,
      params,
      headers,
    });
    return response.data;
  } catch (error) {
    console.log("got error while api get call: ", error);
    return { success: false, message: error };
  }
};

const apiPostCall = async (endpoint, data = {}, headers = {}, params = {}) => {
  try {
    const response = await axios.request({
      method: "POST",
      data,
      url: endpoint,
      headers,
      params,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return { success: false, message: error };
  }
};

export const getTrending = async () => {
  return await apiGetCall(trendingUrl);
};

export const getTrendingMovies = async () => {
  return await apiGetCall(trendingMoviesUrl);
};

export const getPopularMovies = async () => {
  return await apiGetCall(popularMoviesUrl);
};

export const getRecentMovies = async () => {
  return await apiGetCall(recentMoviesUrl);
};

export const getTrendingSeries = async () => {
  return await apiGetCall(trendingSeriesUrl);
};

export const getPopularSeries = async () => {
  return await apiGetCall(popularSeriesUrl);
};

export const getRecentSeries = async () => {
  return await apiGetCall(recentSeriesUrl);
};

const getMovieDetail = async (id) => {
  return await apiGetCall(getMovieUrl(id));
};

const getSeriesDetail = async (id) => {
  return await apiGetCall(getSeriesUrl(id));
};

export const getDetail = async (id, type) => {
  switch (type) {
    case "movie":
      return await getMovieDetail(id);
      break;

    case "series":
      return await getSeriesDetail(id);
      break;

    case "series":
      return await getSeriesDetail(id);
      break;

    default:
      break;
  }
};

export const getAllMovies = async () => {
  return await apiGetCall(movie_url);
};

export const getSimilarMovies = async (id, genre) => {
  let { data } = await apiGetCall(similarMoviesUrl(id, genre));
  if (!data?.length > 0) {
    data = await getAllMovies();
  }
  return data;
};

export const searchMoviesAndSeries = async (query) => {
  return await apiGetCall(searchUrl(query));
};
