import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import List from "../components/List";
import TrendingList from "../components/TrendingList";
import { getRecentMovies, getPopularMovies, getTrendingMovies } from "../api";
import Loader from "../components/loader";

const Movies = () => {
  const [trending, setTrending] = useState([]);
  const [recent, setRecent] = useState([]);
  const [popular, setPopular] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTrendingMovies = async () => {
    const res = await getTrendingMovies();
    return res.data;
  };
  const fetchRecentMovies = async () => {
    const res = await getRecentMovies();
    return res.data;
  };
  const fetchPopularMovies = async () => {
    const res = await getPopularMovies();

    return res.data;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [trendingMovies, recentMovies, popularMovies] = await Promise.all([
        fetchTrendingMovies(),
        fetchRecentMovies(),
        fetchPopularMovies(),
      ]);

      setTrending(trendingMovies);
      setRecent(recentMovies);
      setPopular(popularMovies);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Slider = ({ title, data }) => {
    return (
      <List
        title={title}
        data={data}
        height={"h-[160px] xxs:h-[190px] xs:h-[240px] sm:h-[280px]"}
        width={15}
        breakpoints={{
          480: {
            slidesPerView: 3.5,
          },
          640: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 4.5,
          },
          1024: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        }}
      />
    );
  };

  return (
    <main className="bg-black pt-16 text-white">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="px-2 md:px-4 lg:px-8 pt-2">
            <TrendingList data={trending} />
          </div>

          <div className="px-1 md:px-4 lg:px-8">
            <Slider title={"Recent Movies"} data={recent} />
            <Slider title={"Popular Movies"} data={popular} />
          </div>
        </>
      )}
    </main>
  );
};

export default Layout(Movies);
