import { HiOutlineUpload, HiOutlineViewGrid } from "react-icons/hi";

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/dashboard",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "upload",
    label: "Upload",
    path: "/dashboard/upload",
    icon: <HiOutlineUpload />,
  },
];
