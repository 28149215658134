import React from "react";

const NoResult = () => {
  return (
    <div className="flex flex-col h-[100vh] w-[100vw] items-center justify-center pt-16 bg-black">
      <img alt="no-result" src="noresult.png" className="h-[35%]" />
      <span className="text-[20px] text-white capitalize">No Result found</span>
    </div>
  );
};

export default NoResult