import Header from "./partials/Header";
// import Footer from "./partials/Footer";

const Layout = (WrappedComponent,showHeader = true) => {
  return (props) => (
    <div>
      {showHeader && <Header />}
      <WrappedComponent {...props} />
      {/* <Footer /> */}
    </div>
  );
}

export default Layout