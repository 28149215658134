import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/Layout";
import VideoPlayer from "../components/video";
import { useParams } from "react-router-dom";
import { getDetail, getSimilarMovies } from "../api";
import Loader from "../components/loader";
import getCdnUrl from "../helper/cdnUrl";
import { FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Detail = () => {
  const [data, setData] = useState(null);
  const [similar, setSimilar] = useState([]);
  const [video, setVideo] = useState(null);
  const [season, setSeason] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTelegram, setIsTelegram] = useState({ show: false, value: false });

  useEffect(() => {
    const hasMiniApp = window.Telegram?.WebApp.initData;
    setIsTelegram({ show: true, value: hasMiniApp ? true : false });
  }, []);

  const { id, type } = useParams();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getDetail(id, type);
      setData(data);
      if (data.season) {
        setSeason(data.season);
        if (data.season[0].id) {
          setVideo(data.season[0].id[0]);
        }
      } else {
        const res = await getSimilarMovies(data._id, data.genre);
        setSimilar(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, type]);

  const playListData = useMemo(() => {
    return similar.length > 0 ? similar : season;
  }, [season, similar]);

  const handleClick = (data) => {
    setVideo(data);
  };

  return (
    <main className="bg-black min-h-[100vh] max-h-auto">
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={`wrapper max-w-[1280px] m-auto flex flex-col ${
            isTelegram.value ? "sm:flex-col sm:w-[80%]" : "sm:flex-row"
          } sm:gap-2 gap-4 pt-20 p-4 `}
        >
          <div
            className={`flex flex-col w-full ${
              isTelegram.value ? "" : "sm:w-7/12 md:w-8/12"
            } overflow-x-hidden`}
          >
            <div className="aspect-video rounded-lg overflow-hidden">
              <VideoPlayer
                videoUrls={video?.videoUrls || data?.video?.videoUrls}
                poster={data?.thumbnailUrl || data?.video?.thumbnailUrl}
                isTelegram={isTelegram}
              />
            </div>
            <div className="mt-2">
              <Title data={video?.title || data?.video?.title} />
              <Genre data={data?.genre} />
              <OtherDetails views={data?.views} />
              <Description
                data={data?.description || data?.video?.description}
              />
            </div>
          </div>

          <PlayList
            data={playListData}
            isMovie={similar?.length > 0}
            playingVideoId={video?._id}
            handleClick={handleClick}
            thumbnail={data?.thumbnailUrl}
            isTelegram={isTelegram}
          />
        </div>
      )}
    </main>
  );
};

const OtherDetails = ({ views }) => {
  return (
    <div className="flex items-center text-[12px] mb-2">
      <span className="mr-6">
        <b className="text-gray-300">Subtitle</b>: <b>Urdu</b>
      </span>
      <span>
        <b className="text-gray-300">Views</b>: <b>{views}</b>
      </span>
    </div>
  );
};

const Title = ({ data }) => {
  return <h1 className="text-[17px] font-bold">{data}</h1>;
};

const Genre = ({ data = [] }) => {
  return (
    <div className="flex items-center justify-start flex-row">
      {data.map((genre, index) => {
        return (
          <span
            key={index}
            className="text-[10px] font-semibold text-gray-200 mr-2"
          >
            {genre}
          </span>
        );
      })}
    </div>
  );
};

const Description = ({ data }) => {
  return <p className="text-[12px] text-gray-400 line-clamp-3">{data}</p>;
};

const PlayList = ({
  data,
  isMovie,
  playingVideoId,
  handleClick,
  thumbnail,
  isTelegram,
}) => {
  const [selectSeason, setSelectSeason] = useState(1);
  const [showSeason, setShowSeason] = useState(false);

  const playlistData = useMemo(() => {
    return isMovie ? data : data?.[selectSeason - 1]?.id;
  }, [isMovie, data, selectSeason]);

  const toggleShowSeason = () => {
    setShowSeason(!showSeason);
  };

  return (
    <div
      className={`flex flex-col w-full ${
        isTelegram.value ? "sm:w-[70%]" : "xs:w-[70%] sm:w-5/12 md:w-4/12"
      } h-min max-h-full sm:max-h-[600px] p-3 rounded-xl bg-neutral-900`}
    >
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-[17px] font-semibold tracking-wide">
          {!isMovie ? "Episodes List" : "Similar Movies"}
        </h2>
        {!isMovie && data?.length > 1 && (
          <div className="px-3 py-1 border border-primary relative flex items-center justify-between cursor-pointer">
            <span
              className="text-[14px] text-white capitalize"
              onClick={toggleShowSeason}
            >
              season {selectSeason}
            </span>
            {showSeason && (
              <ul className="absolute rounded top-8 left-0 w-full max-h-28 overflow-y-scroll z-20 bg-white">
                {data?.map((s, i) => {
                  const isLast = i == data.length - 1;
                  const numOfSeason = i + 1;

                  return (
                    <li
                      key={i}
                      onClick={() => {
                        setSelectSeason(numOfSeason);
                        setShowSeason(false);
                      }}
                      className={`text-black text-[12px] py-1 text-center capitalize ${
                        isLast ? "border-b" : ""
                      }`}
                    >
                      season {numOfSeason}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </div>

      <ul className="overflow-y-scroll">
        {playlistData &&
          playlistData
            .filter((item) => item._id !== data?._id)
            .map((item, index) => {
              const isLast = index === data?.length;
              const isPlaying = playingVideoId == item._id;

              return (
                <List
                  key={index}
                  isLast={isLast}
                  data={item?.video || item}
                  imageUrl={thumbnail || item?.video?.thumbnailUrl}
                  description={data?.description || item?.video?.description}
                  isPlaying={isPlaying}
                  handleClick={handleClick}
                  movieId={item?.video ? item._id : null}
                />
              );
            })}
      </ul>
    </div>
  );
};

const List = ({
  isLast,
  data,
  imageUrl,
  description,
  isPlaying,
  handleClick,
  movieId,
}) => {
  const { title } = data;

  const navigate = useNavigate();

  const cdnImageUrl = useMemo(() => {
    return getCdnUrl(imageUrl);
  }, [imageUrl]);

  return (
    <li
      className={`relative flex cursor-pointer ${isLast ? "mb-0" : "mb-2"}`}
      onClick={() => {
        movieId ? navigate(`/movie/${movieId}`) : handleClick(data);
      }}
    >
      {isPlaying && (
        <div className="absolute h-full w-full rounded-md top-0 left-0 bg-black/75 flex items-center justify-start">
          <div className="w-2/6 flex items-center justify-center">
            <FaPlay className="mr-2" />
            <span className="text-[12px] font-semibold">Playing</span>
          </div>
        </div>
      )}
      <img
        className="w-2/6 object-cover h-20 sm:h-24 rounded-lg"
        src={cdnImageUrl}
        alt=""
      />
      <div className="px-2 flex flex-col flex-1 items-start justify-center">
        <h3 className="text-[14px] font-semibold capitalize tracking-wide line-clamp-2">
          {title}
        </h3>
        <p className="text-[10px] leading-[12px] text-gray-400 line-clamp-2">
          {description}
        </p>
        <div>
          <span className="text-[12px] font-semibold text-gray-300">
            2 days ago
          </span>
        </div>
      </div>
    </li>
  );
};

export default Layout(Detail);
