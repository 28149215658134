import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import getCdnUrl from "../helper/cdnUrl";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

const playIcon = "https://play.qayadat.org/assets/theme/default/img/play.svg";

const List = ({
  title,
  data = [],
  height,
  width,
  showSlide,
  showOverlay = false,
  gap,
  breakpoints = {},
}) => {
  const [overlay, setOverlay] = useState(null);

  const defaultBreakpoints = {
    768: {
      slidesPerView: 3.5,
      spaceBetween: gap || 10,
    },
    1024: {
      slidesPerView: 4,
    },
    1280: {
      slidesPerView: 5,
    },
  }

  const swiperOptions = {
    centeredSlides: true,
    initialSlide: 1,
    spaceBetween: 5,
    modules: [Navigation],
    className: height ? height : "h-[160px]",
    direction: "horizontal",
    loop: data.length > 3 ? true : false,
    navigation: true,
    slidesPerView: 3,
    breakpoints:  {
      ...defaultBreakpoints,
      ...breakpoints,
    },
  };

  return (
    <div className="text-white px-1 py-2">
      <div className="mb-2">
        <h2 className="font-sans text-[18px] font-semibold">{title}</h2>
      </div>

      <Swiper {...swiperOptions}>
        {data?.map((item, index) => {
          const type = item.season ? "series" : "movie";
          const redirectUrl = `/${type}/${item._id}`;
          const title = item.title || item?.video?.title;
          const imageUrl = getCdnUrl(
            item?.thumbnailUrl || item?.video?.thumbnailUrl
          );
          return (
            <SwiperSlide
              key={index}
              style={{ width: `${width ? width : 21}%` }}
              onMouseEnter={() => {
                setOverlay(index);
              }}
              onMouseLeave={() => {
                setOverlay(null);
              }}
            >
              <div className="home_hero h-full relative flex-1">
                <Link to={redirectUrl} className="overflow-y-hidden">
                  <img
                    src={imageUrl}
                    alt={`Slide ${index}`}
                    className={`${
                      height && width ? "h-[90%]" : "h-full"
                    } w-full object-cover cursor-pointer ${
                      overlay === index ? "scale-x-105" : "rounded"
                    } transition-all duration-300 ease-in-out`}
                  />
                  {showOverlay && overlay === index && (
                    <div
                      className={`hover-card bg-gradient-to-b from-black/30 to-black/100`}
                    >
                      <img
                        src={playIcon}
                        alt="playIcon"
                        className="h-12 object-cover"
                      />
                      <h3 className="font-bold tracking-widest text-[15px] line-clamp-2">
                        {item.title}
                      </h3>
                      <p className="text-[12px] text-gray-400 line-clamp-2">
                        {item.description}
                      </p>
                    </div>
                  )}
                  {height && width && (
                    <h2 className="text-[12px] mt-0 xs:text-[15px] sm:text-[17px] capitalize line-clamp-1 font-sans tracking-wider xs:mt-1 text-left px-1">
                      {title.toLowerCase()}
                    </h2>
                  )}
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default List;
