import React, { useEffect, useState } from "react";
import List from "../components/List";
import Layout from "../components/Layout";
import { SnapCarousel } from "../components/TrendingList";
import {
  getTrending,
  getTrendingMovies,
  getPopularMovies,
  getRecentMovies,
  getTrendingSeries,
  getPopularSeries,
  getRecentSeries,
} from "../api";
import Loader from "../components/loader";
import { commonParameters } from "../constants";

const Home = () => {
  const [trending, setTrending] = useState([]);
  const [trendingMovies, setTrendingMovies] = useState([]);
  const [popularMovies, setPopularMovies] = useState([]);
  const [recentMovies, setRecentMovies] = useState([]);
  const [trendingSeries, setTrendingSeries] = useState([]);
  const [popularSeries, setPopularSeries] = useState([]);
  const [recentSeries, setRecentSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTrending = async () => {
    const res = await getTrending();
    return res.data;
  };
  const fetchTrendingMovies = async () => {
    const res = await getTrendingMovies();
    return res.data;
  };
  const fetchPopularMovies = async () => {
    const res = await getPopularMovies();
    return res.data;
  };
  const fetchRecentMovies = async () => {
    const res = await getRecentMovies();
    return res.data;
  };
  const fetchTrendingSeries = async () => {
    const res = await getTrendingSeries();
    return res.data;
  };
  const fetchPopularSeries = async () => {
    const res = await getPopularSeries();
    return res.data;
  };
  const fetchRecentSeries = async () => {
    const res = await getRecentSeries();
    return res.data;
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [
        trendingData,
        trendingMoviesData,
        popularMoviesData,
        recentMoviesData,
        trendingSeriesData,
        popularSeriesData,
        recentSeriesData,
      ] = await Promise.all([
        fetchTrending(),
        fetchTrendingMovies(),
        fetchPopularMovies(),
        fetchRecentMovies(),
        fetchTrendingSeries(),
        fetchPopularSeries(),
        fetchRecentSeries(),
      ]);

      setTrending(trendingData);
      setTrendingMovies(trendingMoviesData);
      setPopularMovies(popularMoviesData);
      setRecentMovies(recentMoviesData);
      setTrendingSeries(trendingSeriesData);
      setPopularSeries(popularSeriesData);
      setRecentSeries(recentSeriesData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main className="bg-black pt-20 lg:px-4 sm:px-1">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="h-[40vh] md:h-[50vh] lg:h-[85vh] flex items-center justify-center">
            <SnapCarousel data={trending} />
          </div>

          <div className="py-4">
            <Slider title={"Trending Movies"} data={trendingMovies} />
            <Slider title={"Trending Series"} data={trendingSeries} />
            <Slider title={"Popular Movies"} data={popularMovies} />
            <Slider title={"Popular Series"} data={popularSeries} />
            <Slider title={"Recent Movies"} data={recentMovies} />
            <Slider title={"Recent Series"} data={recentSeries} />
          </div>
        </>
      )}
    </main>
  );
};

const Slider = ({ data, ...props }) => {
  if (data.length < 6) return;
  return <List data={data} {...props} {...commonParameters} />;
};

export default Layout(Home);
