import Video from "./Video";
import { useEffect, useMemo, useRef, useState } from "react";
import getCdnUrl from "../../helper/cdnUrl";
import { default_video_sources } from "../../constants";

const Index = ({ videoUrls, poster, isTelegram }) => {
  const playerRef = useRef(null);
  const [videoSources, setVideoSources] = useState([]);

  const posterUrl = useMemo(() => {
    return getCdnUrl(poster);
  }, [poster]);

  useEffect(() => {
    if (videoUrls) {
      const urlEntries = Object.entries(videoUrls);

      const urls = urlEntries
        .filter(([key]) => key !== "_id")
        .map(([key, value]) => {
          const src = getCdnUrl(value);
          return {
            src,
            type: "video/mp4",
            label: `${key}p`,
            res: key,
          };
        });

      setVideoSources(urls);
    }
  }, [videoUrls]);

  const videoJsOptions = useMemo(
    () => ({
      controls: true,
      responsive: true,
      fluid: true,
      poster: posterUrl,
      autoplay: true,
      muted: false,
      plugins: {},
      sources: videoSources.length > 0 ? videoSources : default_video_sources,
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    }),
    [posterUrl, videoSources]
  );

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  if (!isTelegram.show) return;

  return (
    <Video
      options={videoJsOptions}
      onReady={handlePlayerReady}
      isTelegram={isTelegram}
    />
  );
};

export default Index;
