import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import List from "../components/List";
import TrendingList from "../components/TrendingList";
import { getTrendingSeries, getRecentSeries, getPopularSeries } from "../api";
import Loader from "../components/loader";

const Series = () => {
  const [trending, setTrending] = useState([]);
  const [recent, setRecent] = useState([]);
  const [popular, setPopular] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const fetchTrendingSeries = async () => {
    const res = await getTrendingSeries();
    return res.data;
  };
  const fetchRecentSeries = async () => {
    const res = await getRecentSeries();
    return res.data;
  };
  const fetchPopularSeries = async () => {
    const res = await getPopularSeries();
    return res.data;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [trendingData, recentData, popularData] = await Promise.all([
        fetchTrendingSeries(),
        fetchRecentSeries(),
        fetchPopularSeries(),
      ]);

      setTrending(trendingData);
      setRecent(recentData);
      setPopular(popularData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Slider = ({ title, data }) => {
    return (
      <List
        title={title}
        data={data}
        height={"h-[160px] xxs:h-[190px] xs:h-[240px] sm:h-[280px]"}
        width={15}
        breakpoints={{
          480: {
            slidesPerView: 3.5,
          },
          640: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 4.5,
          },
          1024: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        }}
      />
    );
  };

  return (
    <main className="bg-black pt-16 text-white">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="px-2 md:px-4 lg:px-8 pt-2">
            <TrendingList data={trending} />
          </div>

          <div className="px-1 md:px-4 lg:px-8">
            <Slider title={"Recent Series"} data={recent} />
            <Slider title={"Popular Series"} data={popular} />
          </div>
        </>
      )}
    </main>
  );
};

export default Layout(Series);
