import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { searchMoviesAndSeries } from "../api";
import Loader from "../components/loader";
import getCdnUrl from "../helper/cdnUrl";
import NoResult from "../components/partials/NoResult";
import { FaArrowLeft } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState();

  const [searchParams] = useSearchParams();

  const query = searchParams.get("query");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await searchMoviesAndSeries(query);
      if (res.data) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  if (!isLoading && !data.length > 0) return <NoResult />;

  return (
    <section className="bg-black h-[100vh] flex items-start justify-center">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex h-full w-[95%] m-auto items-center flex-col">
          <div className="flex items-center justify-between w-full">
            <FaArrowLeft size={18} color="gray" className="mr-2" />
            <SearchBar
              query={searchQuery}
              handleChange={handleChange}
              handleClick={() => setSearchQuery("")}
            />
          </div>
          <span className="text-[14px] mb-2 text-center">
            Search Result {`(${data?.length})`}
          </span>
          <div className="flex h-full w-full max-w-[800px] justify-center bg-red-700">
            {data &&
              data.map((item) => {
                const { thumbnailUrl, title, _id, video = {} } = item;
                const imageUrl = getCdnUrl(thumbnailUrl || video?.thumbnailUrl);
                const resultTitle = title || video?.title;
                const type = item?.season ? "series" : "movie";

                return (
                  <Link key={_id} to={`/${type}/${_id}`}>
                    <img
                      className="h-48 w-32 object-cover rounded"
                      src={imageUrl}
                    />
                    <h2 className="text-left capitalize">
                      {resultTitle?.toLowerCase()}
                    </h2>
                  </Link>
                );
              })}
          </div>
        </div>
      )}
    </section>
  );
};

const SearchBar = ({ query, handleChange, handleClick }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-neutral-900 flex-1 my-2 rounded-full border border-gray-500 h-10 px-4 flex items-center justify-center">
      <IoMdSearch
        size={20}
        style={{ fill: "gray" }}
        className="mr-2"
        onClick={() => navigate(-1)}
      />
      <input
        type="text"
        className="flex-1 bg-transparent"
        value={query}
        placeholder="Search here..."
        onChange={handleChange}
      />
      {query?.length > 0 && (
        <MdClose
          size={20}
          style={{ fill: "gray" }}
          color="gray"
          onClick={handleClick}
        />
      )}
    </div>
  );
};

export default Search;
