import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Movies from "./pages/Movies";
import Series from "./pages/Series";
import Login from "./pages/Login";
import Detail from "./pages/Detail";
import Dashboard from "./pages/dashboard/index";
import Layout from "./components/dashboard/AdminLayout";
import "./index.css";
import Search from "./pages/Search";
import Upload from "./pages/dashboard/Upload";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

ReactGA.initialize("G-GMZTG3V7CK");

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/movies" element={<Movies />} />
      <Route path="/series" element={<Series />} />
      <Route path="/login" element={<Login />} />
      <Route path="/:type/:id" element={<Detail />} />
      <Route path="/search" element={<Search />} />
      <Route path="/dashboard" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="upload" element={<Upload />} />
      </Route>
    </Routes>
  );
}

export default App;
