import React from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineLogout } from "react-icons/hi";
import { DASHBOARD_SIDEBAR_LINKS } from "../lib/constant";

import logo from "../../assets/logo.png";

const linkClass =
  "flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base";

export default function Sidebar() {
  return (
    <div className="bg-black w-60 p-3 flex flex-col">
      <Link to={"/"} className="flex items-center gap-2 px-1 py-3">
        <img src={logo} alt="logo" className="h-6 w-6 object-cover" />
        <span className="text-neutral-200 text-lg font-semibold uppercase tracking-wider">
          Islamic Media
        </span>
      </Link>
      <div className="py-8 flex flex-1 flex-col gap-1">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} />
        ))}
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
        <div className={classNames(linkClass, "cursor-pointer text-red-500")}>
          <span className="text-xl">
            <HiOutlineLogout />
          </span>
          Logout
        </div>
      </div>
    </div>
  );
}

function SidebarLink({ link }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={link.path}
      className={`${classNames(
        pathname === link.path
          ? "bg-neutral-700 text-white"
          : "text-neutral-400",
        linkClass
      )} flex`}
    >
      <span className="text-2xl">{link.icon}</span>
      <span
        className={`text-[14px] font-semibold ${
          pathname === link.path ? "text-neutral-100" : "text-neutral-400"
        }`}
      >
        {link.label}
      </span>
    </Link>
  );
}
