function getCdnUrl(url) {
  if (!url) return;
  if (url.includes("wasabisys.com")) {
    return url.replace(
      "s3.ap-southeast-1.wasabisys.com/islamic-media",
      "cdn.islamicmediaofficial.com"
    );
  }
  return url;
}

export default getCdnUrl;
