import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import getCdnUrl from "../helper/cdnUrl";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import { Pagination, Autoplay, Navigation } from "swiper/modules";

const TrendingList = ({ data }) => {
  return (
    <Swiper
      slidesPerView={1}
      initialSlide={1}
      spaceBetween={20}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Autoplay]}
      autoplay={true}
      direction="vertical"
      loop={true}
      className="h-[40vh] sm:h-[50vh] md:h-[60vh] lg:h-[85vh]"
    >
      {data &&
        data.map((item, index) => {
          const type = item.season ? "series" : "movie";
          const imageUrl = getCdnUrl(
            item?.thumbnailUrl || item?.video?.thumbnailUrl
          );
          return (
            <SwiperSlide key={index}>
              <div className="h-full w-full">
                <Link to={`/${type}/${item._id}`}>
                  <img
                    src={imageUrl}
                    alt={`Slide ${index}`}
                    className="h-full w-full object-cover rounded-lg cursor-pointer"
                  />
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export const SnapCarousel = ({ data }) => {
  return (
    <Swiper
      slidesPerView={"auto"}
      centeredSlides={true}
      initialSlide={1}
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Navigation, Autoplay]}
      className="swiper h-[100%]"
      autoplay={true}
      direction="horizontal"
      loop={true}
      navigation={true}
      onSlideChange={(swiper) => {
        swiper.slides.forEach((slide, index) => {
          if (index === swiper.activeIndex) {
            slide.style.opacity = "1";
            slide.style.transform = "scaleY(1)";
          } else {
            slide.style.opacity = "0.6";
            slide.style.transform = "scaleY(0.93)";
          }
        });
      }}
    >
      {data.map((item, index) => {
        const type = item.season ? "series" : "movie";
        const imageUrl = getCdnUrl(
          item?.thumbnailUrl || item?.video?.thumbnailUrl
        );
        return (
          <SwiperSlide key={index} className="w-[55%] md:w-[60%]">
            <div className="home_hero h-full flex-1">
              <Link to={`/${type}/${item?._id}`}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index}`}
                  className="h-full w-full object-cover rounded-lg cursor-pointer"
                />
              </Link>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default TrendingList;
