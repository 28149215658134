import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import logo from "../../assets/logo.png";
import { FaChevronRight, FaBars } from "react-icons/fa";
import { navMenus } from "../../constants";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let lastScrollTop = 500;
    const handleScroll = () => {
      if (window.scrollY > lastScrollTop) {
        setIsScrolling(true);
        lastScrollTop = window.scrollY;
      } else {
        setIsScrolling(false);
        lastScrollTop = window.scrollY;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleQuery = (e) => setQuery(e.target.value);
  const handleClear = () => setQuery("");

  const handleKeyDown = (e) => {
    if (query.length > 3 && e.key === "Enter") {
      console.log("Enter key pressed:", e.target.value);
      navigate(`/search?query=${query}`);
    }
  };

  const closeNavBar = () => setShowNav(false);
  const showNavBar = () => setShowNav(true);

  const Search = ({ style = {} }) => {
    return (
      <SearchBar
        handleQuery={handleQuery}
        handleClear={handleClear}
        query={query}
        onKeyDown={handleKeyDown}
        style={style}
        isMobile={true}
      />
    );
  };

  return (
    <nav
      className={`fixed ${
        isScrolling ? "-top-16" : "top-0"
      } left-0 right-0 z-10 transition-all duration-300`}
    >
      <div className="relative bg-black h-16 flex flex-1 items-center px-4">
        <div>
          <Link to={"/"}>
            <img className="h-12 cursor-pointer" src={logo} alt="Logo" />
          </Link>
        </div>

        <ul className="hidden sm:flex flex-1 items-center justify-center gap-4 font-[600] text-[14px] tracking-wide uppercase">
          {navMenus.map((menu, index) => (
            <Menu data={menu} key={index} />
          ))}
        </ul>

        <div className="hidden sm:flex items-center justify-end relative">
          <SearchBar
            handleQuery={handleQuery}
            handleClear={handleClear}
            query={query}
            onKeyDown={handleKeyDown}
          />
          <AppButton />
        </div>

        <div className="flex sm:hidden absolute top-[50%] right-[5%] -translate-y-1/2">
          <FaBars onClick={showNavBar} />
        </div>

        {/* mobile Nav */}
        {showNav && (
          <div className="flex flex-col py-4 items-center justify-between sm:hidden absolute top-0 left-0 h-[100vh] w-[100vw] bg-black">
            <Search style={{ width: "85%" }} />

            <ul className="flex flex-1 w-[85%] mt-6 flex-col items-start justify-start gap-4 font-[600] text-[14px] tracking-wide uppercase">
              <IoMdClose
                size={22}
                className="absolute top-6 right-2"
                onClick={closeNavBar}
              />
              {navMenus.map((menu, index) => (
                <Menu
                  data={menu}
                  style={{ width: "100%" }}
                  isMobile={true}
                  key={index}
                  closeNavBar={closeNavBar}
                />
              ))}
            </ul>

            <AppButton style={{ width: "85%" }} />
          </div>
        )}
      </div>
    </nav>
  );
};

const SearchBar = ({
  handleQuery,
  handleClear,
  query,
  style = {},
  isMobile = false,
  ...props
}) => {
  return (
    <div
      className="flex h-10 bg-neutral-800 mr-2 px-2 py-2 items-center justify-center rounded-full"
      style={style}
    >
      <IoMdSearch size={20} style={{ fill: "gray" }} className="mr-2" />
      <input
        type="text"
        className={`bg-transparent text-[13px] ${isMobile ? "flex-1" : ""}`}
        placeholder="Search here...."
        value={query}
        onChange={handleQuery}
        {...props}
      />

      <IoMdClose
        size={20}
        className="ml-2 cursor-pointer"
        style={{ fill: query ? "white" : "rgb(38 38 38)" }}
        onClick={handleClear}
      />
    </div>
  );
};

const AppButton = ({ style = {} }) => {
  return (
    <button
      style={style}
      className="py-2 px-3 rounded-md font-semibold uppercase text-[14px] text-white"
    >
      <Link to={"https://cdn.islamicmediaofficial.com/islamic_media.apk"}>
        Watch on App
      </Link>
    </button>
  );
};

const Menu = ({ style = {}, data, isMobile = false, closeNavBar }) => {
  const { route, label } = data;
  return (
    <Link
      onClick={closeNavBar}
      to={route}
      className={`text-gray-300 hover:text-primary ${
        route === "/" ? "text-white" : ""
      } flex items-center justify-between`}
      style={style}
    >
      <span className="capitalize">{label}</span>
      {isMobile && <FaChevronRight color="white" size={12} />}
    </Link>
  );
};

export default Header;
