import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./style.css";
import { useRef, useEffect, useState } from "react";
import { FaFastForward, FaFastBackward, FaChevronRight } from "react-icons/fa";
import {
  IoMdPlay,
  IoMdPause,
  IoMdQrScanner,
  IoMdSettings,
  IoMdOptions,
  IoMdSpeedometer,
  IoMdArrowBack,
  IoMdCheckmark,
} from "react-icons/io";
import { iconDefaultStyle } from "../../constants";
import "@silvermine/videojs-quality-selector/dist/css/quality-selector.css";

// Register the quality selector plugin
require("@silvermine/videojs-quality-selector")(videojs);

export const VideoJS = (props) => {
  const [playing, setPlaying] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [speed, setSpeed] = useState({ show: false, value: 1 });
  const [quality, setQuality] = useState({ show: false, value: "480p" });
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady, isTelegram } = props;

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...options,
          controlBar: {
            ...options.controlBar,
            fullscreenToggle: !isTelegram.value,
            pictureInPictureToggle: !isTelegram.value,
          },
        },
        () => {
          videojs.log("player is ready");
          onReady && onReady(player);

          setDuration(player.duration());

          player.controlBar.addChild("QualitySelector");
        }
      ));

      player.on("loadedmetadata", () => setDuration(player.duration()));
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef, onReady, isTelegram.value]);

  useEffect(() => {
    const player = playerRef.current;

    const updateProgress = () => setPosition(player.currentTime());
    player.on("timeupdate", updateProgress);

    return () => {
      player.off("timeupdate", updateProgress);
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    if (showControls && playing) {
      const timeoutId = setTimeout(() => {
        if (!showSettings && !speed.show && !quality.show) {
          setShowControls(false);
        }
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showControls, playing, showSettings, speed.show, quality.show]);

  useEffect(() => {
    const player = playerRef.current;

    // Check if the player is ready
    if (player) {
      // Set to muted initially
      player.muted(true);

      const handleUserInteraction = () => {
        player.muted(false); // Unmute when user interacts
        window.removeEventListener("click", handleUserInteraction); // Remove listener after first interaction
      };

      // Attach event listener for user interaction
      window.addEventListener("click", handleUserInteraction);

      return () => {
        // Cleanup listener on component unmount
        window.removeEventListener("click", handleUserInteraction);
      };
    }
  }, [playerRef]);

  // function
  const handlePlayPause = (e) => {
    e.stopPropagation();
    const player = playerRef.current;

    if (playing) {
      player.pause();
    } else {
      player.play();
    }
    setPlaying(!playing);
  };

  const handleControls = () => {
    setShowControls(!showControls);
  };

  const handleForward = () => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + 10);
  };

  const handleBackward = () => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() - 10);
  };

  const handlePosition = (e) => {
    const player = playerRef.current;
    const newTime = parseFloat(e.target.value);
    setPosition(newTime);
    player.currentTime(newTime);
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    } else {
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    }
  };

  const handleSettings = () => {
    if (speed.show || quality.show) {
      handleSpeed(false);
      handleQuality(false);
      setShowSettings(false);
    } else {
      setShowSettings(!showSettings);
    }
  };

  const handleSpeed = (value) => {
    setSpeed({ ...speed, show: value });
    setShowSettings(false);
  };

  const handleQuality = (value) => {
    setQuality({ ...quality, show: value });
    setShowSettings(false);
  };

  const handleSpeedChange = (newSpeed) => {
    const player = playerRef.current;
    player.playbackRate(newSpeed);
    setSpeed({ show: false, value: newSpeed });
  };

  const handleQualityChange = (src, label) => {
    const player = playerRef.current;

    // Save the current position before changing the source
    const currentPosition = player.currentTime();

    // Update the video source and set the new quality
    player.src({ src, type: "video/mp4" }); // Adjust `type` if necessary

    // Resume playback from the saved position after source update
    player.ready(() => {
      player.currentTime(currentPosition);
      if (!playing) player.pause();
    });

    setQuality({ show: false, value: label });
  };

  const handleBackToSettings = () => {
    if (speed.show) {
      handleSpeed(false);
    } else {
      handleQuality(false);
    }
    setShowSettings(true);
  };

  return (
    <div
      data-vjs-player
      className="bg-black flex items-center justify-center rounded-xl relative"
    >
      {/* {isTelegram && (
        <div
          className="absolute top-0 left-0 h-full w-full z-10 bg-black/35"
          onClick={handleControls}
        ></div>
      )} */}
      <div ref={videoRef} className="flex flex-1" />
      {!options.controls && showControls && (
        <div
          className="absolute top-0 left-0 h-full w-full bg-black/75 flex flex-col justify-between items-center z-20"
          onClick={handleControls}
        >
          <div className="w-full flex-1 flex items-center justify-center">
            <div className="w-full h-10 flex items-center justify-center">
              <div className="flex items-center justify-center gap-10">
                <FaFastBackward
                  {...iconDefaultStyle}
                  onClick={handleBackward}
                />
                <PlayPauseButton
                  playing={playing}
                  handleClick={handlePlayPause}
                  style={iconDefaultStyle}
                />
                <FaFastForward {...iconDefaultStyle} onClick={handleForward} />
              </div>
            </div>
          </div>
          <div
            className="w-full px-4 py-2"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mb-1">
              <input
                className="w-full h-[2.5px]"
                type="range"
                min="0"
                max={duration}
                value={position}
                onChange={handlePosition}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center gap-4">
                <PlayPauseButton
                  playing={playing}
                  handleClick={handlePlayPause}
                  style={iconDefaultStyle}
                  size={20}
                />
                <span className="text-white text-[12px]">
                  {formatTime(position)} / {formatTime(duration)}
                </span>
              </div>
              <div className="flex items-center justify-between gap-4 relative">
                <div className="bg-black/95 absolute bottom-6 right-8 rounded-lg max-h-32">
                  {showSettings && (
                    <Settings
                      handleQuality={() => handleQuality(true)}
                      handleSpeed={() => handleSpeed(true)}
                    />
                  )}

                  {speed.show && (
                    <PlayBackRates
                      handleSpeedChange={handleSpeedChange}
                      handleBackToSettings={handleBackToSettings}
                      value={speed.value}
                    />
                  )}

                  {quality.show && (
                    <QualitiesOptions
                      videoUrl={options.sources}
                      handleQualityChange={handleQualityChange}
                      handleBackToSettings={handleBackToSettings}
                      value={quality.value}
                    />
                  )}
                </div>

                <IoMdSettings
                  {...iconDefaultStyle}
                  size={20}
                  onClick={handleSettings}
                  className="cursor-pointer"
                />
                <IoMdQrScanner
                  {...iconDefaultStyle}
                  size={20}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const QualitiesOptions = ({
  videoUrl,
  handleQualityChange,
  handleBackToSettings,
  value,
}) => {
  return (
    <div className="p-2">
      <div
        className="flex items-center justify-start border-b w-20"
        onClick={handleBackToSettings}
      >
        <IoMdArrowBack {...iconDefaultStyle} size={16} className="mr-2" />
        <span className="capitalize text-[14px]">Quality</span>
      </div>
      <ul className="py-1 overflow-y-auto max-h-24">
        {videoUrl.map((source, index) => {
          const { label, src } = source;

          return (
            <li
              key={index}
              className="text-[12px] cursor-pointer relative text-center"
              onClick={() => handleQualityChange(src, label)}
            >
              {value === label && (
                <IoMdCheckmark
                  {...iconDefaultStyle}
                  size={14}
                  className="absolute right-2"
                />
              )}
              <span className="flex-1 text-center">{`${label}`}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const PlayBackRates = ({ handleSpeedChange, handleBackToSettings, value }) => {
  return (
    <div className="p-2">
      <div
        className="flex items-center justify-start border-b w-20"
        onClick={handleBackToSettings}
      >
        <IoMdArrowBack {...iconDefaultStyle} size={16} className="mr-2" />
        <span className="capitalize text-[14px]">Speed</span>
      </div>
      <ul className="py-1 overflow-y-auto max-h-24">
        {Array(7)
          .fill(0)
          .map((item, index) => {
            const playbackSpeed = 0.25 * (index + 2);
            return (
              <li
                key={index}
                className="text-center text-[12px] cursor-pointer relative"
                onClick={() => handleSpeedChange(playbackSpeed)}
              >
                {value === playbackSpeed && (
                  <IoMdCheckmark
                    {...iconDefaultStyle}
                    size={14}
                    className="absolute right-2"
                  />
                )}
                <span>{`${playbackSpeed}x`}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const Settings = ({ handleQuality, handleSpeed }) => {
  return (
    <ul className="flex flex-col gap-2 p-2">
      <li
        className="text-[12px] font-semibold flex items-center justify-between gap-4 cursor-pointer"
        onClick={handleSpeed}
      >
        <div className="flex items-center justify-center">
          <IoMdSpeedometer {...iconDefaultStyle} size={14} className="mr-1" />
          <span className="capitalize">Speed</span>
        </div>
        <FaChevronRight {...iconDefaultStyle} size={12} />
      </li>
      <li
        className="text-[12px] font-semibold flex items-center justify-between gap-4 cursor-pointer"
        onClick={handleQuality}
      >
        <div className="flex items-center justify-center">
          <IoMdOptions {...iconDefaultStyle} size={14} className="mr-1" />
          <span className="capitalize">Quality</span>
        </div>
        <FaChevronRight {...iconDefaultStyle} size={12} />
      </li>
    </ul>
  );
};

const PlayPauseButton = ({ playing, handleClick, size = 30, style }) => {
  const iconStyle = { ...style, size };
  return (
    <div className="cursor-pointer" onClick={handleClick}>
      {playing ? <IoMdPause {...iconStyle} /> : <IoMdPlay {...iconStyle} />}
    </div>
  );
};

export default VideoJS;
