export const commonParameters = {
  showOverlay: true,
  gap: 11,
};

export const iconDefaultStyle = {
  color: "white",
  size: 20,
};

export const default_video_url = "https://www.w3schools.com/html/mov_bbb.mp4";

export const default_video_sources = [
  {
    src: "https://www.w3schools.com/html/mov_bbb.mp4",
    type: "video/mp4",
    label: "1080p",
    res: 1080,
  },
  {
    src: "https://www.w3schools.com/html/mov_bbb.mp4",
    type: "video/mp4",
    label: "720p",
    res: 720,
  },
  {
    src: "https://www.w3schools.com/html/mov_bbb.mp4",
    type: "video/mp4",
    label: "480p",
    res: 480,
  },
  {
    src: "https://www.w3schools.com/html/mov_bbb.mp4",
    type: "video/mp4",
    label: "360p",
    res: 360,
  },
];

export const navMenus = [
  { label: "Home", route: "/" },
  { label: "movies", route: "/movies" },
  { label: "series", route: "/series" },
];
